import gql from "graphql-tag"

const getMcProvider = gql`
  query Provider {
    providers {
      name
      slug
      website
      deepLink {
        ios
        android
      }
      app {
        android
        ios
      }
      website
    }
  }
`

export default getMcProvider
