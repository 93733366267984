import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GET_MC_PROVIDER from "../graphql/getMcProvider"

const ProviderListPage = () => (
  <Layout>
    <SEO title="List of Mobility Provider" />
    <h1>List of Mobility Provider.</h1>
    <h2>Providers for Bikes, Scooter, MotoScooter ...</h2>

    <StaticQuery
      query={graphql`
        query mcProviderListQuery {
          multicycles {
            providers {
              name
              website
              slug
            }
          }
        }
      `}
      render={data => {
        return (
          <div>
            <ul className="provider-list" style={{ listStyleType: "none" }}>
              {data.multicycles.providers.map(prov => (
                <li key={prov.slug}>
                  <Link to={`/network/${prov.slug}`}>{prov.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )
      }}
    />

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ProviderListPage

// export const MC_PROVIDER_QUERY = gql`
//   query getProviderQuery {
//     providers {
//       name
//       slug
//       website
//       app {
//         android
//         ios
//       }
//       website
//     }
//   }
// `
